import(/* webpackMode: "eager" */ "/app/node_modules/animate.css/animate.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@little-worker/hestia/dist/style.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["Provider"] */ "/app/src/app/[locale]/provider.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/assets/styles/globals.css");
